


















































































































































































































































































































































































































































































































































































































import { Action, Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import {
  AddScheduleAdRequest,
  AddVideoAdRequest,
  EditVideoAdRequest,
  ScheduledAds,
} from "@/store/videoAds/types";
import moment from "moment";
import parser from "cron-parser";
import VueCronEditorBuefy from "vue-cron-editor-buefy";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import { RouteName } from "@/router/types";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";
import ConfirmDialog from "../ConfirmDialog.vue";

@Component({
  components: {
    VueCronEditorBuefy,
    VueCtkDateTimePicker,
    ButtonMenuItemList,
    ConfirmDialog,
  },
})
export default class VideoAds extends Vue {
  @Action("videoAds/editVideoAd")
  editVideoAd!: (data: EditVideoAdRequest) => void;
  @Action("videoAds/addVideoAd")
  addVideoAd!: (data: AddVideoAdRequest) => void;
  @Action("videoAds/addScheduleAd")
  addScheduleAd!: (data: AddScheduleAdRequest) => void;
  @Action("videoAds/fetchAllVideoAds")
  fetchAllVideoAds!: () => Promise<void>;
  @Action("videoAds/deleteVideoAd")
  deleteVideoAdRequest!: (id: string) => void;
  @Action("videoAds/deleteScheduledAd")
  deleteScheduledAdRequest!: (id: string) => void;
  @Action("videoAds/fetchBroadcastIds")
  fetchBroadcastIds!: () => Promise<void>;
  @Action("videoAds/fetchAllScheduledAds")
  fetchAllScheduledAds!: () => Promise<void>;

  @Getter("videoAds/getAllVideoAds")
  readonly getAllVideoAds!: VideoAds[];
  @Getter("videoAds/getAllBroadcastIds")
  readonly getAllBroadcastIds!: VideoAds[];
  @Getter("videoAds/getAllScheduledAds")
  readonly getAllScheduledAds!: ScheduledAds[];
  @Getter("videoAds/getVideoAdError")
  readonly getVideoAdError!: string;
  @Getter("videoAds/getScheduledAdError")
  readonly getScheduledAdError!: string;
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  RouteName = RouteName;

  selectedBroadcasts = [] as Broadcast[];

  styleObject = { border: "1px solid black" };
  isSelectedAll = false;
  videoUpload = false;
  dialog = false;
  dialogCron = false;
  cronJob = "";
  scheduleAdId = "";
  scheduleAdName = "";
  videoAdName = "";
  broadcastId = "";
  iDItemToDelete = "";
  nameItemToDelete = "";
  mimeType = "";
  search = "";
  searchScheduler = "";
  videoName = "";
  linkVideo = "";
  clickLink = "";
  deleteTitle = "";
  deleteTitleType = "";
  formIsValid = false;
  //editAd
  dialogEdit = false;
  durationEdit = this.$t("5 seconds");
  videoNameEdit = "";
  linkVideoEdit = "";
  clickLinkEdit = "";
  editFormIsValid = false;
  mimeTypeEdit = "";
  editAdId = "";
  //
  //schedule tab
  date = "";
  minDate = moment().add(1, "minute").toISOString();
  scheduleSelected = this.$t("Now");
  nowSelected = true;
  dateSelected = false;
  cronSelected = false;
  //
  shceduleFormIsValid = false;
  duration = this.$t("5 seconds");
  durations = [
    this.$t("Instantly"),
    this.$t("3 seconds"),
    this.$t("5 seconds"),
    this.$t("10 seconds"),
    this.$t("Not closeable"),
  ];
  headers = [
    {
      text: this.$t("Video Ad Name"),
      value: "altText",
    },
    {
      text: this.$t("Link"),
      value: "clickLink",
    },
    {
      text: this.$t("Closable after"),
      value: "closableAfterSeconds",
      filterable: false,
    },
    {
      text: this.$t("dataTable.actions"),
      value: "id",
      filterable: false,
      width: "150px",
      align: "center",
    },
  ];
  headersScheduler = [
    {
      text: this.$t("Ad name"),
      value: "schedulable.name",
    },
    {
      text: this.$t("Description"),
      value: "name",
    },
    {
      text: this.$t("Link"),
      value: "schedulable.clickLink",
    },
    {
      text: this.$t("Broadcast"),
      value: "broadcast.broadcastName",
      filterable: false,
    },
    {
      text: this.$t("Date"),
      value: "cron",
      filterable: false,
    },
    {
      text: this.$t("Closable after"),
      value: "schedulable.closableAfterSeconds",
      filterable: false,
    },
    {
      text: this.$t("Last Executed"),
      value: "lastExecutedAt",
      filterable: false,
    },
    {
      text: this.$t("dataTable.actions"),
      value: "id",
      filterable: false,
      width: "150px",
      align: "center",
    },
  ];
  language = {
    every: this.$tc("Every"),
    mminutes: this.$tc("minute(s)"),
    hoursOnMinute: this.$tc("hour(s) on minute"),
    daysAt: this.$tc("day(s) at"),
    at: this.$tc("at"),
    onThe: this.$tc("On the"),
    dayOfEvery: this.$tc("day, of every"),
    monthsAt: this.$tc("month(s), at"),
    everyDay: this.$tc("Every"),
    mon: this.$tc("Mon"),
    tue: this.$tc("Tue"),
    wed: this.$tc("Wed"),
    thu: this.$tc("Thu"),
    fri: this.$tc("Fri"),
    sat: this.$tc("Sat"),
    sun: this.$tc("Sun"),
    hasToBeBetween: this.$tc("Has to be between"),
    and: this.$tc("and"),
    minutes: this.$tc("MINUTES"),
    hourly: this.$tc("HOURLY"),
    daily: this.$tc("DAILY"),
    weekly: this.$tc("WEEKLY"),
    monthly: this.$tc("MONTHLY"),
    advanced: this.$tc("ADVANCED"),
    cronExpression: this.$tc("cron expression:"),
  };

  linkUrlRules: Array<(value: string) => true | string> = [
    (v: string) => !!v || this.$tc("Valid Link URL is required!"),
    (v: string) =>
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ) || this.$tc("Link URL must be valid!"),
  ];
  urlRules: Array<(value: string) => true | string> = [
    (v: string) => !!v || this.$tc("Valid MP4 or WebM video URL is required!"),
    (v: string) =>
      /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        v
      ) || this.$tc("Video URL must be valid!"),
    (v: string) => {
      return (
        this.urlVideoCheck(v) ||
        this.$tc("Please insert a valid MP4 or WebM Video URL!")
      );
    },
  ];
  scheduledDescriptionRule: Array<(value: string) => true | string> = [
    (v: string) =>
      v.length <= 64 || this.$tc("Description must be less than 64 characters"),
  ];
  urlVideoCheck(linkVideo: string) {
    const mime = this.checkMimeType(linkVideo.split(".").splice(-1)[0]);
    if (
      mime == "webm" ||
      mime == "mp4" ||
      mime == "m4p" ||
      mime == "m4a" ||
      mime == "m4b" ||
      mime == "m4r"
      // || mime == "m4v" ||
      // mime == "mov" ||
      // mime == "qt" ||
      // mime == "asf" ||
      // mime == "wma" ||
      // mime == "wmv" ||
      // mime == "avi" ||
      // mime == "3gp" ||
      // mime == "m1v" ||
      // mime == "ogm" ||
      // mime == "ogg" ||
      // mime == "ogv"
    ) {
      return true;
    }
  }
  get form(): Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
  } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      resetValidation: () => boolean;
    };
  }

  othersLanguage() {
    if (this.selectedBroadcasts.length == 2) {
      return this.$tc("plusOne");
    } else {
      return this.$tc("plusMore");
    }
  }

  selectAll() {
    if (!this.isSelectedAll) this.selectedBroadcasts = [...this.broadcasts];
    else this.selectedBroadcasts = [];
    this.isSelectedAll = !this.isSelectedAll;
  }

  scheduledAdBroadcastIds(index: number) {
    const list = Array<string>();
    this.getAllScheduledAds[index].broadcasts.forEach((element) => {
      if (element.broadcastName) {
        list.push(element.broadcastName);
      }
    });
    return list;
  }

  async created() {
    await this.fetchAllVideoAds();
    await this.fetchBroadcastIds();
    await this.fetchAllScheduledAds();
  }

  get ScheduledAdsLength() {
    if (this.getScheduledAdError) {
      return 0;
    } else {
      return this.getAllScheduledAds.length;
    }
  }

  get VideoAdsLength() {
    if (this.getVideoAdError) {
      return 0;
    } else {
      return this.getAllVideoAds.length;
    }
  }

  get disabled() {
    return !(
      this.linkVideo.length >= 1 &&
      this.clickLink.length >= 1 &&
      this.videoName.length >= 1 &&
      this.formIsValid
    );
  }
  get disableEditSend() {
    return !(
      this.linkVideoEdit.length >= 1 &&
      this.clickLinkEdit.length >= 1 &&
      this.videoNameEdit.length >= 1 &&
      this.editFormIsValid
    );
  }
  get disabledScheduleSend() {
    this.minDate = moment().add(1, "minute").toISOString();

    if (
      this.nowSelected &&
      this.scheduleAdName &&
      this.scheduleAdId &&
      this.selectedBroadcasts.length > 0 &&
      this.shceduleFormIsValid
    ) {
      return false;
    } else if (
      !this.nowSelected &&
      this.cronJob &&
      this.scheduleAdName &&
      this.scheduleAdId &&
      this.selectedBroadcasts.length > 0 &&
      this.shceduleFormIsValid
    ) {
      this.date = "";
      return false;
    } else if (
      !this.nowSelected &&
      this.date &&
      this.scheduleAdName &&
      this.scheduleAdId &&
      this.selectedBroadcasts.length > 0 &&
      this.shceduleFormIsValid
    ) {
      this.cronJob = "";
      return false;
    } else return true;
  }

  get closableAfterTime() {
    if (this.duration === this.$t("Instantly")) {
      return 0;
    } else if (this.duration === this.$t("3 seconds")) {
      return 3;
    } else if (this.duration === this.$t("5 seconds")) {
      return 5;
    } else if (this.duration === this.$t("10 seconds")) {
      return 10;
    } else if (this.duration === this.$t("Not closeable")) {
      return -1;
    } else {
      return 0;
    }
  }

  get closableAfterTimeEdit() {
    if (this.durationEdit === this.$t("Instantly")) {
      return 0;
    } else if (this.durationEdit === this.$t("3 seconds")) {
      return 3;
    } else if (this.durationEdit === this.$t("5 seconds")) {
      return 5;
    } else if (this.durationEdit === this.$t("10 seconds")) {
      return 10;
    } else if (this.durationEdit === this.$t("Not closeable")) {
      return -1;
    } else {
      return 0;
    }
  }

  selectScheduleTime(selection: string) {
    if (selection == "now") {
      this.scheduleSelected = this.$t("Now");
      this.date = "";
      this.cronJob = "";
      this.nowSelected = true;
      this.dateSelected = false;
      this.cronSelected = false;
    } else if (selection == "scheduled") {
      this.scheduleSelected = this.$t("Scheduled");
      this.minDate = moment().add(1, "minute").toISOString();
      this.cronJob = "";
      this.nowSelected = false;
      this.dateSelected = true;
      this.cronSelected = false;
    } else if (selection == "cron") {
      this.scheduleSelected = this.$t("Cron Job");
      this.date = "";
      this.nowSelected = false;
      this.dateSelected = false;
      this.cronSelected = true;
    }
  }

  cronParser(cron: string, date: string) {
    if (cron) {
      try {
        const interval = parser.parseExpression(cron);
        return moment(interval.next().toISOString()).format(
          "YYYY/MM/DD HH:mm:ss"
        );
      } catch (err) {
        return "/";
      }
    } else if (date) {
      try {
        return moment(date).format("YYYY/MM/DD HH:mm:ss");
      } catch (err) {
        return "/";
      }
    } else {
      return this.$t("Instantly");
    }
  }

  lastExecutedDate(date: string) {
    if (date) {
      return moment(date.toString()).format("YYYY/MM/DD HH:mm:ss");
    } else {
      return "/";
    }
  }

  checkMimeType(mime: string) {
    if (
      mime == "mp4" ||
      mime == "m4p" ||
      mime == "m4a" ||
      mime == "m4b" ||
      mime == "m4r"
    ) {
      return "mp4";
    } else if (mime == "mov" || mime == "qt") {
      return "quicktime";
    } else if (mime == "asf" || mime == "wma" || mime == "wmv") {
      return "ms-asf";
    } else if (mime == "avi") {
      return "x-msvideo";
    } else if (mime == "m4v") {
      return "x-m4v";
    } else if (mime == "3gp") {
      return "3gpp";
    } else if (mime == "m1v") {
      return "mpeg";
    } else if (mime == "webm") {
      return "webm";
    } else if (mime == "ogv" || mime == "ogm" || mime == "ogg") {
      return "ogg";
    } else {
      return mime;
    }
  }

  async sendVideoAdApi() {
    this.mimeTypeEdit = this.checkMimeType(
      this.linkVideo.split(".").splice(-1)[0]
    );
    await this.addVideoAd({
      name: this.videoName,
      altText: this.videoName,
      clickLink: this.clickLink,
      video: {
        src: this.linkVideo,
        mimeType: "video/" + this.mimeType,
      },
      closableAfterSeconds: this.closableAfterTime,
    });
    this.videoNameEdit = "";
    this.clickLinkEdit = "";
    this.linkVideoEdit = "";
    setTimeout(() => {
      this.fetchAllVideoAds();
    }, 500);
  }

  async sendEditAdApi() {
    this.mimeTypeEdit = this.checkMimeType(
      this.linkVideoEdit.split(".").splice(-1)[0]
    );
    await this.editVideoAd({
      id: this.editAdId,
      name: this.videoNameEdit,
      altText: this.videoNameEdit,
      clickLink: this.clickLinkEdit,
      video: {
        src: this.linkVideoEdit,
        mimeType: "video/" + this.mimeTypeEdit,
      },
      closableAfterSeconds: this.closableAfterTimeEdit,
    });
    this.dialogEdit = false;
    (this.editAdId = ""), (this.videoNameEdit = "");
    this.clickLinkEdit = "";
    this.linkVideoEdit = "";
    setTimeout(() => {
      this.fetchAllVideoAds();
    }, 500);
  }

  async sendScheduleAdApi() {
    const broadcastIdList = this.selectedBroadcasts.map(
      (element) => element.id
    );

    if (this.nowSelected && !this.date && !this.cronJob) {
      await this.addScheduleAd({
        name: this.scheduleAdName,
        schedulableId: this.scheduleAdId,
        schedulableType: "VideoAd",
        broadcastIds: broadcastIdList,
      });
    } else if (this.cronSelected && this.cronJob) {
      await this.addScheduleAd({
        name: this.scheduleAdName,
        schedulableId: this.scheduleAdId,
        schedulableType: "VideoAd",
        broadcastIds: broadcastIdList,
        cron: this.cronJob,
      });
    } else if (this.dateSelected && this.date) {
      await this.addScheduleAd({
        name: this.scheduleAdName,
        schedulableId: this.scheduleAdId,
        schedulableType: "VideoAd",
        broadcastIds: broadcastIdList,
        scheduledAt: moment(this.date).format("X"),
      });
    }
    this.scheduleAdName = "";
    this.scheduleAdId = "";
    this.cronJob = "";
    this.nowSelected = true;
    this.dialogCron = false;
    setTimeout(() => {
      this.fetchAllScheduledAds();
    }, 500);
  }

  closableDuration(duration: string) {
    if (duration == "0") {
      return this.$t("Instantly");
    } else if (duration == "3") {
      return this.$t("3 seconds");
    } else if (duration == "5") {
      return this.$t("5 seconds");
    } else if (duration == "10") {
      return this.$t("10 seconds");
    } else if (duration == "-1") {
      return this.$t("Not closeable");
    } else {
      return duration;
    }
  }

  async deleteAd(adId: string) {
    this.dialog = false;
    if (this.deleteTitleType == "videoAd") {
      await this.deleteVideoAdRequest(adId);
      setTimeout(() => {
        this.fetchAllScheduledAds();
        this.fetchAllVideoAds();
      }, 500);
    } else if (this.deleteTitleType == "scheduledAd") {
      await this.deleteScheduledAdRequest(adId);
      setTimeout(() => {
        this.fetchAllScheduledAds();
      }, 500);
    }
  }

  showDeletedItem(item: string, name: string, type: string) {
    this.iDItemToDelete = item;
    this.nameItemToDelete = name;
    this.deleteTitleType = type;
    if (type == "videoAd") {
      this.deleteTitle = this.$tc(
        "Are you sure you want to delete this Video Ad?"
      );
    } else if (type == "scheduledAd") {
      this.deleteTitle = this.$tc(
        "Are you sure you want to delete this Scheduled Ad?"
      );
    }
    this.dialog = !this.dialog;
  }
  showScheduledItem(id: string, videoAdName: string) {
    this.selectScheduleTime("Now");
    this.scheduleAdId = id;
    this.videoAdName = videoAdName;
    this.dialogCron = !this.dialogCron;
  }
  showEditAd(
    id: string,
    videoNameEdit: string,
    linkVideoEdit: string,
    clickLinkEdit: string,
    durationEdit: string
  ) {
    (this.editAdId = id), (this.videoNameEdit = videoNameEdit);
    this.linkVideoEdit = linkVideoEdit;
    this.clickLinkEdit = clickLinkEdit;
    this.durationEdit = this.closableDuration(durationEdit);
    this.dialogEdit = !this.dialogEdit;
  }
}
