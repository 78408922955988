










import VideoAds from "@/components/General/VideoAds.vue";
import PageTemplate from "@/components/PageTemplate.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    VideoAds,
  },
})
export default class VideoAdsView extends Vue {
  RouteName = RouteName;
}
